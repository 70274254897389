import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
 
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { MdClose } from "react-icons/md";
 
import MenuIcon from "../assets/MenuIcon.svg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IconButton, Paper } from "@mui/material";
import Nav_Logo from "../assets/Nav_Logo.svg";

export const Navbar = ({handleClickOurTeam}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const routes = [
    // { to: "/", title: "Home" },
    // { to: "/services", title: "Services" },
    { to: "/", title: "Products" },
    { to: "/", title: "Team" },
    { to: "/", title: "Contact us" },
  ];
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleClick = (title) => {
    if(title=='Menu'){

    }else if(title=='Contact us'){
       let url= 'https://mail.google.com/mail/?view=cm&fs=1&to=dannyk.virtualex@gmail.com' 

       window.open(url,'_blank')
    }else if(title=='Team'){
      console.log('hi')
      handleClickOurTeam('Team')
    }
    else if(title=='Products'){
      handleClickOurTeam('Products')
    }
  };
  const xsmall = useMediaQuery(theme.breakpoints.up("xs"));
  const small = useMediaQuery(theme.breakpoints.up("sm"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const list = (anchor) => (
    <Box
      sx={{ width: "100vw", padding: "20px" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {routes?.map((el, index) => (
          <ListItem key={el.title} disablePadding>
            <ListItemButton>
              <p
                style={{ color: "#5AB3F3", fontSize: "32px", fontWeight: 400 }}
                onClick={
                  el.title !== "Contact us" && el.title !== "Team" && el.title !== "Products"
                    ? () => {
                      handleNavigation(el.to);
                     } 
                    : ()=>{
                      handleClick(el.title)
                    } 
                }
              >
                {" "}
                {el.title}
              </p>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <div
        className={`flex bg-[#050a30] max-sm:justify-between justify-center   max-h-[56px] p-4 items-center`}
      >
        {!small && <img className="cursor-pointer" onClick={()=>{
          handleNavigation("/")
        }} src={Nav_Logo} alt="" />}
        {small ? (
          <div className="max-w-[1200px] flex min-w-[35%] w-full justify-around  ">
            <img className="cursor-pointer" onClick={()=>{
              handleNavigation("/")
            }} src={Nav_Logo} alt="" />
            {routes.map((el,index) => {
              return (
                <p
                key={index}
                  className="cursor-pointer text-[20px] px-2 !z-10 text-black-800 text-white hover:text-[#5AB3F3] font-light"
                  onClick={
                    el.title !== "Contact us" && el.title !== "Team" && el.title !== "Products"
                      ? () => {
                        handleNavigation(el.to);
                       } 
                      : ()=>{
                        handleClick(el.title)
                      } 
                  }
                >
                  {el.title}
                </p>
              );
            })}
          </div>
        ) : (
          <React.Fragment key={"right"}>
            <Button onClick={toggleDrawer("right", true)}>
              <img className="cursor-pointer" src={MenuIcon} />
            </Button>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
            >
              <Paper style={{ background: "black", height: "100vh" }}>
                <div className="absolute top-0 right-0 ">
                  <IconButton
                    onClick={toggleDrawer("right", false)}
                    size="large"
                  >
                    <MdClose className="text-white" />
                  </IconButton>
                </div>
                {list("right")}
              </Paper>
            </Drawer>
          </React.Fragment>
        )}
      </div>
    </>
  );
};
