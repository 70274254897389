import { Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";

import { NotFound } from "./Components/NotFound";
import { Services } from "./Pages/Services";
import { Ourteam } from "./Pages/Ourteam";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/" element={<Ourteam />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
