import React from "react";
import Lottie from "lottie-react";
import search from "../assets/search.json";
export const NotFound = () => {
  return (
    <div className="top-[40%] left-[50%] absolute -translate-x-[50%] -translate-y-[50%]">
      <Lottie
        style={{ width: "320px", height: "320px" }}
        animationData={search}
        loop={true}
      />
      <h1>The page you’re looking for can’t be found.</h1>
    </div>
  );
};
