import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination,
  EffectCoverflow,
  Thumbs,
} from "swiper/modules";
import SwiperCore from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/thumbs";

import Profile1 from "../assets/images/Profiles VirtualeX/Profiles VirtualeX-1.jpg";
import Profile2 from "../assets/images/Profiles VirtualeX/Profiles VirtualeX-2.jpg";
import Profile3 from "../assets/images/Profiles VirtualeX/Profiles VirtualeX-3.jpg";
import Profile4 from "../assets/images/Profiles VirtualeX/Profiles VirtualeX-4.jpg";
import Profile5 from "../assets/images/Profiles VirtualeX/Profiles VirtualeX-5.jpg";
import Profile6 from "../assets/images/Profiles VirtualeX/Profiles VirtualeX-6.jpg";
import Profile from "../assets/images/Profiles VirtualeX/Profiles VirtualeX.jpg";

SwiperCore.use([Pagination, EffectCoverflow, Autoplay]);

export const Ourteam = React.forwardRef((props, ref) => {
  const titles = [
    "purple",
    "blue",
    "orange",
    "#43B2F7",
    "green",
    "yellow",
    "pink",
    "purple",
    "blue",
    "orange",
    "#43B2F7",
    "green",
    "yellow",
    "pink",
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (instance) => {
    let curr = instance.realIndex;
    console.log(curr);

    if (curr == 7) {
      setActiveIndex(0);
    } else if (curr == 8) {
      setActiveIndex(1);
    } else if (curr == 9) {
      setActiveIndex(2);
    } else if (curr == 10) {
      setActiveIndex(3);
    } else if (curr == 11) {
      setActiveIndex(4);
    } else if (curr == 12) {
      setActiveIndex(5);
    } else if (curr == 13) {
      setActiveIndex(6);
    } else {
      setActiveIndex(curr);
    }
  };
  useEffect(() => {
    console.log({ activeIndex });
  }, [activeIndex]);

  // else if(curr==8){
  //   setActiveIndex(1)
  // }
  // else if(curr==9){
  //   setActiveIndex(2)
  // }else{
  // setActiveIndex( curr)}
  // }

  //  function rotateArray(arr,n,d)
  // {
  //     let p = 1;
  //         while (p <= d) {
  //             let last = arr[0];
  //             for (let i = 0; i < n - 1; i++) {
  //                 arr[i] = arr[i + 1];
  //             }
  //             arr[n - 1] = last;
  //             p++;
  //         }

  //       return arr
  // }

  return (
    <div ref={ref} className="pt-10 max-w-[1700px] m-auto ">
      <p className="text-[45px] font-medium max-sm:text-lg ">
        The team that does the wonders
      </p>
      <Swiper
        loop={true}
        onActiveIndexChange={updateIndex}
        breakpoints={{
          0: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          767: {
            slidesPerView: 2.5,
            spaceBetween: 24,
          },
          988: {
            slidesPerView: 4,
            spaceBetween: 48,
          },
        }}
        effect="coverflow"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        autoplay={{
          delay:2000,
          disableOnInteraction: false,
        }}
        direction="horizontal"
        slidesPerView={4}
        freeMode={true}
        centeredSlides
        style={{ marginTop: "40px" }}
        spaceBetween={30}
      >
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile1} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile2} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile3} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile4} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Profile6} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile1} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile2} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile3} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile4} alt="" />
        </SwiperSlide>
        <SwiperSlide style={{ width: "400px", height: "400px" }}>
          <img src={Profile5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Profile6} alt="" />
        </SwiperSlide>
      </Swiper>

      <div className="m-auto flex mt-5 max-sm:-mt-2  justify-around max-w-[200px] ">
        {titles?.map((el, index) => {
          let active;
          switch (index) {
            case index == 7: {
              active = 0;
            }

            case index == 8: {
              active = 1;
            }

            case index == 9: {
              active = 2;
            }

            case index == 10: {
              active = 3;
            }

            case index == 11: {
              active = 4;
            }

            case index == 12: {
              active = 5;
            }

            case index == 13: {
              active = 6;
            }

            default:
              active = index;
          }

          if (index <= 6) {
            console.log({ active });
            return (
              <div
                key={index}
                className={`h-3  ${
                  activeIndex == active
                    ? "w-6 rounded-md elongatedDiv "
                    : "w-3 rounded-full normalDiv"
                } px-1`}
                style={{ background: el }}
              ></div>
            );
          }
        })}
      </div>
    </div>
  );
});
