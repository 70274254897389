import React from "react";
// import logo from "../../assets/light-logo.svg"
import logo from "../../assets/light-logo.svg";
import { Navbar } from "../Navbar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const Hero = ({ className, id, handleClick }) => {
  const theme = useTheme();
  const xsmall = useMediaQuery(theme.breakpoints.up("xs"));
  const small = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div
      id={id}
      // style={{
      //   background: 'linear-gradient(180deg, #B2C8FF 0%, #5BABC4 96.35%)'
      // }}
      className={`${className} bg-[#050a30] w-full relative    mb-[10px] ${
        !small ? " h-[480px]" : "h-[640px] "
      }`}
    >
      <Navbar handleClickOurTeam={handleClick} />
      <div className=" absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]  flex items-center flex-col  justify-around ">
        <img className="max-w-[643px] max-sm:max-w-[320px] " src={logo} alt="" />
        <p
          className={`py-5 ${
            !small ? "text-[16px]" : "text-[32px]"
          } text-white  font-light max-w-[560px] min-w-[280px] w-full text-center`}
        >
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore
          dolorum eos.
        </p>

        {/* <button
          // style={{ boxShadow: "4px 6px 4px 0px rgba(0, 0, 0, 0.25)" }}
          onClick={()=>{handleClick('HomeXEngine')}}
          className={`mt-5 text-white bg-[#002D68] py-2 px-4 min-w-[183px] max-sm:text-sm rounded-full`}
        >
          Our Products
        </button> */}
      </div>
    </div>
  );
};
