import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const SubProducts = ({ logo, description, button, tag }) => {
  const theme = useTheme();

  const small = useMediaQuery(theme.breakpoints.up("sm"));
  const [Containerstyles, setContainerstyle] = useState(
    ""
  );

  useEffect(() => {
    if (tag == "HomeXR") {
      setContainerstyle(`${Containerstyles} bg-black text-white`);
    } else if (tag == "AutoXEngine") {
      setContainerstyle(`${Containerstyles} bg-[#05316B] text-white`);
    }
  }, []);

  return (
    <div className={`relative ${Containerstyles} `}>
      {/* <div className={`${Containerstyles} bg-[rgba(0, 0, 0, 0.5)]`}></div> */}
      {/* <video
        className="absolute object-cover w-full h-full"
        controls={false} autoplay="autoplay" loop="true" muted defaultmuted playsinline
        src={ tag=="HomeXR"? "https://res.cloudinary.com/des4i2xu7/video/upload/v1698652421/pexels_videos_2759477_2160p_dj6b8u.mp4":"https://res.cloudinary.com/des4i2xu7/video/upload/v1698736753/pexels-shvets-production-7547023_2160p_ivplfu.mp4"}
      ></video> */}
      <div className={' bg-[rgba(0,0,0,0.3)] px-4 relative pt-16 flex flex-col items-center   max-lg:h-[480px] h-[620px]'}>
      <img className="z-10 w-[278px]  " src={logo} alt="" />
      <p className={`max-w-[500px] py-5 z-10 max-sm:text-sm`}>{description}</p>
      <div className="flex z-10">
        <button className={"coming-soon z-10 max-md:py-0 p-[14px]"}>
          {button}
        </button>
        {!small && <button className="z-10 notifyFlex ">Notify me</button>}
      </div>
      {small && <button className="  z-10 notifyMeButton ">Notify me</button>}
      </div>
    </div>
  );
};
