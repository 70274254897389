import React from 'react'

export const Bottom = () => {
  return (
    <div className='h-[100px] max-sm:h-[200px]  relative ' >
       <div className=' h-10 mx-sm:h-7 absolute bottom-0  bg-black w-full'>
       <p className='  font-sans text-xs min-w-[320px] text-white text-center py-2 '>
        Copyright © 2023 VirtualeX ®. All rights reserved.
        </p>
       </div>
    </div>
  )
}
