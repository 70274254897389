import React, { useEffect, forwardRef, useRef, useState } from "react";
import logo from "../../assets/homeXEngine.svg";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const HomeXEngine = forwardRef(({ className, id },ref) => {
  const theme = useTheme();
  const xsmall = useMediaQuery(theme.breakpoints.up("xs"));
  const small = useMediaQuery(theme.breakpoints.up("sm"));
  const [video_url,setvideo_url]= useState(null)
 const video= useRef()
 
 
  return (
    <div
    ref={ref}
      id={id}
      className={`${className} mb-[10px] ${
        !small ? "  h-[480px]" : "h-[640px]"
      } w-full relative z-10 `}
    >
       
      <video ref={video}   autoPlay loop muted playsInline className="absolute z-2 h-full w-full object-cover" src={'https://res.cloudinary.com/des4i2xu7/video/upload/v1698774704/homeXEngine_jwbe8d.mp4'}  ></video>
        <div style={{backgroundImage:'linear-gradient(0deg, rgba(25,68,124,0.9) 0%, rgba(116,140,172,0.5) 100%)'}} className="absolute w-full h-full !bg-[]">
          <div className="  absolute top-[40%] left-[50%] -translate-x-[50%] -translate-y-[50%]  flex items-center flex-col  justify-around ">
          <img className="max-sm:w-[300px] w-[485px]" src={logo} alt="" />
          <p
            className={`py-5 text-white text-[32px] max-sm: text-sm  font-light min-w-[260px] max-w-[560px] w-full text-center`}
          >
            Home Design, Your Way
          </p>
          <div className="flex items-center ">


          <button className={"coming-soon z-10 max-md:py-0 p-[14px]"}>
            Coming Soon
          </button>
            {/* <p className="text-[25px] mx-2 flex items-center text-[#5AB3F3] cursor-pointer">
              <span className=" hover:underline">Learn More</span>{" "}
              <AiOutlineRight size={20} />{" "}
            </p>
            <p className=" text-[25px] mx-2 flex items-center text-[#5AB3F3] cursor-pointer ">
              <span className=" hover:underline">Try</span>{" "}
              <AiOutlineRight size={20} />{" "}
            </p> */}
          </div>
        </div>
       </div>
      
    </div>
  );
});
