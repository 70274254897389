import React from "react";
import { SubProducts } from "../ResuableComponents/SubProducts";
import { Grid } from "@mui/material";
import logo from "../../assets/homeXEngine.svg";
import homeXRwhite from "../../assets/HomeXRWhite.svg";
import AutoXEngine from "../../assets/AutoXEngineWhite.svg";
export const LowerSection = ({ className, id }) => {
  return (
    <div
      id={id}
      className={`${className} w-full relative  max-md:p-0 lg:px-[10px] xl:px-[10px]   `}
    >
      <div
        // className='grid gap-[10px] grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2'
        className="flex gap-[10px] flex-col sm:flex-col   md:flex-col lg-flex-row xl:flex-row"
      >
        <div className="  w-full min-w-[320px]">
          <SubProducts
            logo={homeXRwhite}
            description={
              "Transform your spaces"
            }
            button={"Coming Soon"}
            tag="HomeXR"
          />
        </div>
        <div className="  w-full min-w-[320px]">
          <SubProducts
            logo={AutoXEngine}
            description={
              "Lorem ipsum dolor sit amet, consectetur"
            }
            button={"Coming Soon"}
            tag="AutoXEngine"
          />
        </div>
       
      </div>
      {/* <div
        // className='grid sm:flex-row-reverse mt-[10px] gap-[10px] grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2'
        className="flex gap-6 flex-col sm:flex-col-reverse  mt-6 md:flex-col-reverse lg-flex-row xl:flex-row"
      >
         <div className="w-full min-w-[320px]">
          <SubProducts
            logo={AutoXEngine}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
            }
            button={"Coming Soon"}
            tag="AutoXEngine"
          />
        </div>
        <div className="w-full min-w-[320px]">
          <SubProducts
            logo={homeXRwhite}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
            }
            button={"Coming Soon"}
            tag="HomeXR"
          />
        </div>
       
       
      </div> */}
    </div>
  );
};
