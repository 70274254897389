import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Hero } from '../Components/HomeComponents/Hero'
import { HomeXEngine } from '../Components/HomeXEngine/HomeXEngine'
import { LowerSection } from '../Components/HomeXEngine/LowerSection';
import { Jewels } from '../Components/HomeXEngine/12Jewels';
import { Ourteam } from './Ourteam';
import { Bottom } from '../Components/Bottom';
 
export const Home = () => {
 
  const [activeComponent, setActiveComponent] = useState('Hero');
   const viewHomeXEngine= useRef(null)
   const viewOurTeam= useRef(null)

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
         setActiveComponent( entry.target.id)
        }
      });
    };
  
    const observerRef = useRef(null);
  
    const handleClick=useCallback ((name)=>{
      console.log(name)
      
      setActiveComponent(name)
      if(name=='Team'){
        viewOurTeam.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }else{
      viewHomeXEngine.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
     })

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.7, // Adjust this threshold as needed
      };
  
      observerRef.current = new IntersectionObserver(handleIntersection, options);
  
      document.querySelectorAll('.component').forEach((element) => {
        observerRef?.current.observe(element);
      });
  
      return () => {
        if (observerRef?.current) {
          observerRef?.current.disconnect();
        }
      };
    }, []);
  

useEffect(()=>{
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
},[])

//  console.log(activeComponent)
  
  return (
    <div className=''>
      <div className='customScroll '>
        <div className= {activeComponent=='Hero'?'filledRec':'filledcircle'}  ></div>
        <div className= {activeComponent=='Jewels'?'filledRec':'filledcircle'} ></div>
        <div className= {activeComponent=='HomeXEngine'?'filledRec':'filledcircle'} ></div>
        <div className= {activeComponent=='Lower'?'filledRec':'filledcircle'} ></div> 
      </div>
        
         <Hero  handleClick={handleClick} className='component' id ='Hero'/>
         <div >
           <Jewels  className='component' id ='Jewels'/>
         </div>
         <div >
           <HomeXEngine ref={viewHomeXEngine} className='component' id ='HomeXEngine'/>
         </div>
       
         <LowerSection className='component' id ='Lower'/>
       <Ourteam ref={viewOurTeam} />
       <Bottom/>
    </div>
  )
}
