import React, { useEffect, forwardRef, useRef } from "react";
import logo from "../../assets/jewelslogo.svg";

import { AiOutlineRight } from "react-icons/ai";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
 
export const Jewels =({ className, id }) => {
  const theme = useTheme();
  const xsmall = useMediaQuery(theme.breakpoints.up("xs"));
  const small = useMediaQuery(theme.breakpoints.up("sm"));
 const video= useRef()

// useEffect(()=>{
//   var isPlaying = video.current.currentTime > 0 && !video.current.paused && !video.current.ended 
//     && video.current.readyState > video.current.HAVE_CURRENT_DATA;
//     if(!isPlaying){
//       video.current.play()
//     }
  
//   //  console.log(isPlaying)
// },[])
   
  return (
    <div
      id={id}
      style={{
        overflow:'hidden'
        // background: "linear-gradient(180deg, #FFF -99.7%, #002D68 100%)",
      }}
      className={`${className} mb-[10px] ${
        !small ? "  h-[480px]" : "h-[640px]"
      } w-full relative z-10 `}
    >
       
    
      <video    ref={video} preload="none" autoPlay loop muted playsInline className="absolute z-2 h-full w-full object-cover" src={"https://res.cloudinary.com/des4i2xu7/video/upload/v1699268126/compressed_jewelry_zzgm1p.mp4"}  ></video>
       
        <div style={{backgroundImage:'linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(254,232,190,0.5) 100%)'}} className="absolute w-full h-full !bg-[]">
          <div className="  absolute top-[40%] left-[50%] -translate-x-[50%] -translate-y-[50%]  flex items-center flex-col  justify-around ">
          <img className="max-sm:w-[300px]" src={logo} alt="" />
          <p
            className={`py-5 text-white text-[32px] max-sm: text-sm  font-light min-w-[260px] max-w-[560px] w-full text-center`}
          >
            Home Design, Your Way
          </p>
          <div className="flex items-center ">
            {/* <p className="text-[25px] mx-2 flex items-center text-[#5AB3F3] cursor-pointer">
              <span className=" hover:underline">Learn More</span>{" "}
              <AiOutlineRight size={20} />{" "}
            </p>
            <p className=" text-[25px] mx-2 flex items-center text-[#5AB3F3] cursor-pointer ">
              <span className=" hover:underline">Try</span>{" "}
              <AiOutlineRight size={20} />{" "}
            </p> */}
             <button className={"coming-soon z-10 max-md:py-0 p-[14px]"}>
             Coming on 24th Jan
        </button>
          </div>
        </div>
       </div>
      
    </div>
  );
};
